import React from "react"
import { graphql, Link } from "gatsby"

import { StaticImage } from 'gatsby-plugin-image'

import '../../../../node_modules/slick-carousel/slick/slick.css'
import '../../../../node_modules/slick-carousel/slick/slick-theme.css'

import Layout from '../../../components/layout'
import SEO from '../../../components/seo'
import FeatureSide from '../../../components/feature-side'
import FeatureRelated from '../../../components/feature-related'

const Home = ({ data }) => {
  return (
    <Layout>
      <SEO
        pagetitle="社会人のあなたへ！乗り越える強さをくれる名言！"
        pagepath="/feature/06/"
      />
      <main>
        <div className="l-feature__mv">
          <div className="l-feature__bg">
            <StaticImage
							src="../../../assets/images/feature/business01.jpg"
							alt="社会人のあなたへ！乗り越える強さをくれる名言！"
						/>
          </div>
          <div className="l-feature__above">
            <h3 className="l-feature__hdg">社会人のあなたへ！乗り越える強さをくれる名言！</h3>
          </div>
        </div>
        <div className="l-feature">
          <div className="l-wrap">
            <div className="l-feature__item">
              <div className="l-feature__box">

                <div className="l-feature__main">

                  <div className="l-feature__trunk">
                    <div className="l-feature__paragraph">
                      <h2 className="l-feature__lead">
                        <span>人間関係、満員電車、終わらない仕事。</span>
                        <span>社会人になって、こんなに人生が大変なんだと知った。</span>
                        <span>
                          だからこそ、この戦場でがんばろう。名言といっしょにがんばろう。<br />
                          この世界を少しでも良くすることができるかもしれないから。
                        </span>
                      </h2>
                    </div>

                    <div className="l-feature__paragraph">
                      <div className="l-feature__wrap">
                        <div className="l-feature__num">01.</div>
                        <div className="l-feature__quote">
                          {data.post1.edges.map(( {node }) => {
                            return (
                              <div className="info__item" key={node.id}>
                                <div className="info__item__details">
                                  <h3 className="info__item__ttl">
                                    {node.title}
                                    <span className="info__item__name">
                                      <Link to={`/person/${node.category.slug}`}>{node.category.name}</Link>
                                    </span>
                                  </h3>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                        <p>時間が癒せない傷はありません。</p>
                        <p>あれ程の苦しみも、いつの間にか癒やされているものです。</p>
                        <p>賢者はそれを知っています。だから<span className="marker-b">必要以上に苦しみません</span>。</p>
                        <p>この言葉を、賢者ほどに有効利用できないかもしれませんが、<span className="marker-y">実践することは私たちにもできます</span>。</p>
                        <p>ぜひ、輝かしい未来に焦点をあわせてください。</p>
                      </div>

                      <div className="l-feature__wrap">
                        <div className="l-feature__num">02.</div>
                        <div className="l-feature__quote">
                          {data.post2.edges.map(( {node }) => {
                            return (
                              <div className="info__item" key={node.id}>
                                <div className="info__item__details">
                                  <h3 className="info__item__ttl">
                                    {node.title}
                                    <span className="info__item__name">
                                      <Link to={`/person/${node.category.slug}`}>{node.category.name}</Link>
                                    </span>
                                  </h3>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                        <p>歴史に残る名著、「夜と霧」の作者、ヴィクトール・フランクル。</p>
                        <p>ナチスドイツによるユダヤ人収容施設での壮絶な体験を元に、<span className="marker-b">r人間が如何にして生きるのか</span>を解き明かしました。</p>
                        <p>そんなフランクルの言葉は、限りない説得力を持って私たちに訴えかけてきます。</p>
                        <p>どのような絶望的な状況にあっても、私たちは常に<span className="f-l">「どう行動するか」</span>という意志があります。</p>
                        <p>状況は他人よって影響を受けます。しかし、<span className="marker-b">自分の意志は他人から影響されることのない聖域</span>です。</p>
                        <p>どうか、<span className="marker-b">正しく生きる強い意志</span>をもってください。</p>
                        <p>あなたがあなたたる理由は、<span className="f-l">どんな行動をしたか</span>、のみが規定します。</p>
                        <p>辛くなったらこの言葉に戻りましょう。</p>
                      </div>

                      <div className="l-feature__wrap">
                        <div className="l-feature__num">03.</div>
                        <div className="l-feature__quote">
                          {data.post3.edges.map(( {node }) => {
                            return (
                              <div className="info__item" key={node.id}>
                                <div className="info__item__details">
                                  <h3 className="info__item__ttl">
                                    {node.title}
                                    <span className="info__item__name">
                                      <Link to={`/person/${node.category.slug}`}>{node.category.name}</Link>
                                    </span>
                                  </h3>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                        <p>人生は苦に満ちています。</p>
                        <p>仏教の開祖、ゴータマ・シッダールタは、<span className="f-l">四聖諦</span>という教えでこれを説明しました。</p>
                        <p>私たちは、煩悩があります。エゴと言い換えても構いません。</p>
                        <p>煩悩、エゴとは、<span className="marker-b">自分だけが幸福になりたいという思い</span>です。</p>
                        <p>ゴータマ・シッダールタはこの煩悩が、<span className="f-l">苦悩の原因だと断言</span>します。</p>
                        <p>私たちが煩悩で生きる限り、苦悩はセットなのです。</p>
                        <p>だから、<span className="marker-y">人生に苦があることは当たりまえ</span>の話です。</p>
                        <p>賢者は、苦悩があることを嘆きません。<span className="marker-y">苦悩をどう乗り越えるかにフォーカス</span>します。</p>
                        <p>ニーチェは、そのことを知り、この言葉を残したのでしょう。</p>
                        <p>あなたにもニーチェの強さが宿りますように。</p>
                      </div>

                      <div className="l-feature__wrap">
                        <div className="l-feature__num">04.</div>
                        <div className="l-feature__quote">
                          {data.post4.edges.map(( {node }) => {
                            return (
                              <div className="info__item" key={node.id}>
                                <div className="info__item__details">
                                  <h3 className="info__item__ttl">
                                    {node.title}
                                    <span className="info__item__name">
                                      <Link to={`/person/${node.category.slug}`}>{node.category.name}</Link>
                                    </span>
                                  </h3>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                        <p>２０２１年は大谷翔平選手が、MLBを席巻した年です。</p>
                        <p>ベーブ・ルース以来の二刀流選手として大躍進しました。</p>
                        <p>大谷選手の活躍により、目に触れることが多くなったベーブ・ルースという人物。</p>
                        <p>彼も多くの名言を残したことで知られます。</p>
                        <p><span className="f-l">諦めないやつに勝てっこない。</span></p>
                        <p>素晴らしいですね。</p>
                        <p>人生において負けとは、<span className="marker-b">諦めた瞬間</span>に訪れます。</p>
                        <p>表面上、どんなに負けたとしても<span className="marker-b">諦めない限り勝負は続いています</span>。</p>
                        <p>この真理を知っていれば、<span className="marker-b">あなたの人生で負けはなくなります。</span></p>
                        <p>ただ、<span className="f-l">諦めずに努力すればいい</span>のです。</p>
                        <p>２０歳でも６０歳でも関係ありません。諦めなければ必ず勝てます。</p>
                      </div>

                      <div className="l-feature__wrap">
                        <div className="l-feature__num">05.</div>
                        <div className="l-feature__quote">
                          {data.post5.edges.map(( {node }) => {
                            return (
                              <div className="info__item" key={node.id}>
                                <div className="info__item__details">
                                  <h3 className="info__item__ttl">
                                    {node.title}
                                    <span className="info__item__name">
                                      <Link to={`/person/${node.category.slug}`}>{node.category.name}</Link>
                                    </span>
                                  </h3>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                        <p>偉大な音楽家、ベートーヴェンの人生には大きな障害がありました。</p>
                        <p>徐々に難聴が進行し、<span className="marker-b">４０歳を迎えるころには全ろう</span>となってしまいました。</p>
                        <p>音楽家として致命的な障害。しかし彼は諦めませんでした。</p>
                        <p>彼はその後も、精力的に活動を続け、歴史的な名作を残しました。</p>
                        <p>そんな彼の功績を見れば、この言葉の重みがより一層感じられます。</p>
                        <p><span className="marker-y">自身の不幸を嘆くことなく、自暴自棄になることなく、ただ耐え忍び活動を続けました。</span></p>
                        <p>そんなベートーヴェンが歴史に残るのは当然のことだと思います。</p>
                        <p>人生はどんな状況でも諦めてはいけない。そんな真理を教えてくれる言葉です。</p>
                      </div>

                      <div className="l-feature__wrap">
                        <div className="l-feature__num">06.</div>
                        <div className="l-feature__quote">
                          {data.post6.edges.map(( {node }) => {
                            return (
                              <div className="info__item" key={node.id}>
                                <div className="info__item__details">
                                  <h3 className="info__item__ttl">
                                    {node.title}
                                    <span className="info__item__name">
                                      <Link to={`/person/${node.category.slug}`}>{node.category.name}</Link>
                                    </span>
                                  </h3>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                        <p>忍耐は、人間に必要な資質です。</p>
                        <p>知恵者はそれを知っています。だから<span className="marker-b">忍耐することに前向きに耐えます。</span></p>
                        <p>忍耐なくして成功なし。</p>
                        <p><span className="f-l">忍耐は必要なことだと割り切っている人を知恵者</span>と言ってもいいかもしれません。</p>
                        <p>ぜひ、知恵者になってください。忍耐することを喜んでください。</p>
                        <p><span className="marker-b">その忍耐は成功に繋がります。その忍耐は成長に繋がります。</span></p>
                      </div>

                      <div className="l-feature__wrap">
                        <div className="l-feature__num">07.</div>
                        <div className="l-feature__quote">
                          {data.post7.edges.map(( {node }) => {
                            return (
                              <div className="info__item" key={node.id}>
                                <div className="info__item__details">
                                  <h3 className="info__item__ttl">
                                    {node.title}
                                    <span className="info__item__name">
                                      <Link to={`/person/${node.category.slug}`}>{node.category.name}</Link>
                                    </span>
                                  </h3>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                        <p>中華民国籍の日本の元プロ野球選手、王貞治。</p>
                        <p>１３年連続の本塁打王に輝くなど、日本プロ野球界の伝説です。</p>
                        <p>その輝かしいキャリアの裏には、<span className="marker-b">人知れぬ苦悩と努力の日々があった</span>ことでしょう。</p>
                        <p>記録や、華々しいホームランばかりがフィーチャーされがちですが、その背後には苦悩があることを忘れてはいけません。</p>
                        <p>長年、第一線で活躍し続けるとはどんな苦労があったことでしょう。</p>
                        <p>そんな、彼の人生を象徴するような言葉です。</p>
                      </div>

                      <div className="l-feature__wrap">
                        <div className="l-feature__num">08.</div>
                        <div className="l-feature__quote">
                          {data.post8.edges.map(( {node }) => {
                            return (
                              <div className="info__item" key={node.id}>
                                <div className="info__item__details">
                                  <h3 className="info__item__ttl">
                                    {node.title}
                                    <span className="info__item__name">
                                      <Link to={`/person/${node.category.slug}`}>{node.category.name}</Link>
                                    </span>
                                  </h3>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                        <p>日本人は不安を感じやすい民族です。</p>
                        <p>それは<span className="marker-b">セロトニントランスポーター</span>というタンパク質で説明できます。</p>
                        <p>セロトニントランスポーターは３つの型があります。SS型、SL型、LL型です。</p>
                        <p>SS型の特徴は、幸せホルモンと呼ばれるセロトニンを保持することができずに慢性的にセロトニン不足になります。結果、不安を感じやすくなります。</p>
                        <p>LL型は対象的に、幸福感を感じやすく、前向きで楽天的な傾向があります。</p>
                        <p>特に日本人はSS型の割合が多く、なんと<span className="f-l">全体の68.2%がSS型</span>です。</p>
                        <p>生来的に不安を感じやすい日本人だから、将来を不安に感じることは当然といえば当然です。</p>
                        <p>だからこそ、<span className="f-l">この不安症を解決しよう</span>と決意しましょう！</p>
                        <p>遺伝に負けていられません。<span className="marker-b">遺伝は強力な性向ですが、克服できないものではありません。</span></p>
                        <p>セネカのように、不安の本質を知り、前向きに対処しましょう。</p>
                      </div>

                      <div className="l-feature__wrap">
                        <div className="l-feature__num">09.</div>
                        <div className="l-feature__quote">
                          {data.post9.edges.map(( {node }) => {
                            return (
                              <div className="info__item" key={node.id}>
                                <div className="info__item__details">
                                  <h3 className="info__item__ttl">
                                    {node.title}
                                    <span className="info__item__name">
                                      <Link to={`/person/${node.category.slug}`}>{node.category.name}</Link>
                                    </span>
                                  </h3>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                        <p>イギリスの植民地支配にあったインドにおいて、<span className="marker-b">「非暴力・不服従運動」</span>を展開しインド独立に大きく貢献したマハトマ・ガンディー。</p>
                        <p>マハトマは本名ではありません。<span className="f-l">「偉大な魂」</span>という意味です。彼に対する敬意が込められた呼び名です。</p>
                        <p>ガンディーが傾倒した、<span className="marker-b">古代インド哲学ヴェーダーンタ</span>において『他を害さない』ことの重要性が説かれます。</p>
                        <p>ヒンドゥー教も、同じくインドから生まれた仏教も戒律の一番目は非暴力です。</p>
                        <p><span className="marker-y">人を害することはいけないことだ。誰もが知っている事実です。</span></p>
                        <p>しかし、それを<span className="f-l">実行できる強さを持っている人</span>はどれだけいるでしょうか？</p>
                        <p>強さとは、筋肉の強度を言うのではありません。</p>
                        <p><span className="f-l">心のキャパシティの広さ</span>を強さというのです。</p>
                        <p>この時代、マハトマ・ガンディーほどの強さを持った人がいたでしょうか？</p>
                        <p>相手の暴力に対して、暴力で返さないことの困難さは言うまでもありません。</p>
                        <p>彼の強さが国をまとめ上げ、独立に繋がりました。</p>
                        <p>インドが古代から大切にしてきた真理の価値観で、インドは独立を果たしました。</p>
                        <p>ガンディーの強さは、許すという行動に表現されました。私たちも強くありましょう。</p>
                      </div>

                      <div className="l-feature__wrap">
                        <div className="l-feature__num">10.</div>
                        <div className="l-feature__quote">
                          {data.post10.edges.map(( {node }) => {
                            return (
                              <div className="info__item" key={node.id}>
                                <div className="info__item__details">
                                  <h3 className="info__item__ttl">
                                    {node.title}
                                    <span className="info__item__name">
                                      <Link to={`/person/${node.category.slug}`}>{node.category.name}</Link>
                                    </span>
                                  </h3>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                        <p>例えば、人に裏切られたとします。</p>
                        <p>あなたの心は怒りで燃え上がり、不愉快なストレスから逃れることができません。</p>
                        <p>どうやって復讐してやろうか、そればかりを考えてしまう。</p>
                        <p>こんな状態があったとします。</p>
                        <p>この場面を分析してみると、あなたは、<span className="marker-b">彼に心を奪われています。</span></p>
                        <p>彼に対して悪しき感情を向けることに集中しています。</p>
                        <p>その間、<span className="f-l">あなたは他人を幸福にしたり、自分を成長させることに時間を使えません。</span></p>
                        <p>この状況、<span className="f-l">シャクに思いませんか？</span></p>
                        <p><span className="marker-b">なんで、あんなやつに私の貴重な時間を奪われているんだろう。</span></p>
                        <p>その事実に気づくはずです。</p>
                        <p>では、最善の対策とはなんでしょうか？</p>
                        <p><span className="f-l">許すこと</span>です。許すことで彼の呪縛から逃れられます。</p>
                        <p>あなたは、あなたの時間を使うことができます。</p>
                        <p>裏切られて、あるいは傷つけられて辛い思いにさいなまれるときは、思い出してください。</p>
                        <p><span className="marker-y">許すことが一番自分にメリットがあるのだと。</span></p>
                        <p>北野がいうように、<span className="marker-b">相手にいいことしてやった</span>と考えるもの良いアイデアです。</p>
                        <p>相手に対して憎しみが生まれたら、条件反射的に許してください。それがあなたを一番幸せにする方法です。</p>
                      </div>

                    </div>
                  </div>
                  <div className="l-feature__trunk">
                    <FeatureRelated />
                  </div>
                </div>

                <div className="l-feature__data">
                  <FeatureSide data={data} />
                </div>

              </div>

            </div>

          </div>
        </div>
      </main>
    </Layout>
  )
}

export const query = graphql`
  query {
    post1: allContentfulBlogPost(
      filter: {id: {eq: "aeb2730b-77b8-5ce0-8267-7087f7b73a76"}}
    ) {
      edges {
        node {
          id
          slug
          title
          category {
            id
            slug
            name
          }
        }
      }
    }

    post2: allContentfulBlogPost(
      filter: {id: {eq: "19afaced-8b58-5731-9616-ea16a3b7341d"}}
    ) {
      edges {
        node {
          id
          slug
          title
          category {
            id
            slug
            name
          }
        }
      }
    }

    post3: allContentfulBlogPost(
      filter: {id: {eq: "51f7755b-8499-598c-8861-90ad032d1fd0"}}
    ) {
      edges {
        node {
          id
          slug
          title
          category {
            id
            slug
            name
          }
        }
      }
    }

    post4: allContentfulBlogPost(
      filter: {id: {eq: "cd4f57a0-09f2-5d97-9542-e08c42ecdfda"}}
    ) {
      edges {
        node {
          id
          slug
          title
          category {
            id
            slug
            name
          }
        }
      }
    }

    post5: allContentfulBlogPost(
      filter: {id: {eq: "a10bb370-b7cc-540d-be9d-7308c4bf1d9f"}}
    ) {
      edges {
        node {
          id
          slug
          title
          category {
            id
            slug
            name
          }
        }
      }
    }

    post6: allContentfulBlogPost(
      filter: {id: {eq: "f6926749-afff-569b-ba73-4bb73aa74401"}}
    ) {
      edges {
        node {
          id
          slug
          title
          category {
            id
            slug
            name
          }
        }
      }
    }

    post7: allContentfulBlogPost(
      filter: {id: {eq: "87fe18f1-ec48-5cdb-ad13-dc541aece54a"}}
    ) {
      edges {
        node {
          id
          slug
          title
          category {
            id
            slug
            name
          }
        }
      }
    }

    post8: allContentfulBlogPost(
      filter: {id: {eq: "96ea7fbf-6963-5461-ae4b-67d8bf3e865f"}}
    ) {
      edges {
        node {
          id
          slug
          title
          category {
            id
            slug
            name
          }
        }
      }
    }

    post9: allContentfulBlogPost(
      filter: {id: {eq: "701fac6e-303b-5322-ab24-4f31db8af217"}}
    ) {
      edges {
        node {
          id
          slug
          title
          category {
            id
            slug
            name
          }
        }
      }
    }

    post10: allContentfulBlogPost(
      filter: {id: {eq: "8538cf3c-bd61-50e5-8ad9-8088d76af2e5"}}
    ) {
      edges {
        node {
          id
          slug
          title
          category {
            id
            slug
            name
          }
        }
      }
    }

    cate: allContentfulCategory {
      edges {
        node {
          informations {
            informations
          }
          selfie {
            gatsbyImageData(layout: FULL_WIDTH)
            description
          }
        }
      }
    }

    allCate: allContentfulCategory(
      sort: {fields: sort, order: DESC},
      limit: 20
    ) {
      edges {
        node {
          name
          slug
          id
          selfie {
            gatsbyImageData(layout: FULL_WIDTH)
            description
          }
        }
      }
    }

    tags: allContentfulTags {
      edges {
        node {
          title
          slug
          id
        }
      }
    }
  }
`

export default Home